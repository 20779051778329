import { useForm, ValidationError } from '@formspree/react';

export default function EmailForm() {
    const [state, handleSubmit] = useForm("xqkvvedb");
    if (state.succeeded) {
        return(<div className="email-form">
            <h1 id="email-sent">EMAIL SENT!</h1>
        </div>);
    }
    return (
        <form className="email-form" onSubmit={handleSubmit}>
            <p className="contact-details email-label" htmlFor="email">
                Email Address
            </p>
            <input
                id="email"
                className="mx-auto"
                type="email" 
                name="email"
                placeholder='EMAIL ADDRESS'
            />
            <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
            />
            <br/>
            <textarea
                id="message"
                name="message"
                placeholder='MESSAGE'
            />
            <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
            />
            <button id="submit-email" className="" type="submit" disabled={state.submitting}>
                Send
            </button>
        </form>
    );
}